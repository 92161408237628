var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('nav',{staticClass:"breadcrumb is-large",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'negotiatorStart', params: { id: _vm.$route.params.id } }}},[_c('span',[_vm._v("Start")])])],1),_c('li',[_c('router-link',{attrs:{"to":{
            name: 'negotiatorPayLess',
            params: { id: _vm.$route.params.id }
          }}},[_c('span',[_vm._v("Pay Less")])])],1),_c('li',{staticClass:"is-active"},[_c('router-link',{attrs:{"to":{
            name: 'negotiatorPayLessSystemError',
            params: { id: _vm.$route.params.id }
          }}},[_c('span',[_vm._v("Error")])])],1)])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_c('span',{staticClass:"icon is-large"},[_c('font-awesome-icon',{style:({ color: 'red' }),attrs:{"size":"lg","icon":"exclamation-circle"}})],1),_c('span',[_vm._v(_vm._s(_vm.instanceConfig.systemErrorTitle))])])]),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.instanceConfig.systemErrorContent)+" ")])])])]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"button",attrs:{"to":{ name: 'makePayment', params: { id: _vm.$route.params.id } },"tag":"button"}},[_vm._v("Home")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }